import logoImg from "./logo.svg";
import { isUrl } from "@/utils/url";
import { PROXY_HOST } from "@/configs";
import "./home.css";
import { useCallback, useEffect, useRef, useState } from "react";

interface IHistoryItem {
  name: string;
  url: string;
}

const storageKey = "ProxyHistory";
const maxHistory = 10;
const getHistory = (): IHistoryItem[] => {
  const str = localStorage.getItem(storageKey);
  return str ? JSON.parse(str) : [];
};

const addHistory = function (url: string) {
  let history = getHistory();
  const location = new URL(url);
  const name = `${location?.hostname}${location?.pathname}`;
  history = history.filter((h: IHistoryItem) => h.url !== url);
  const newItem: IHistoryItem = { name, url };
  history.push(newItem);

  if (history.length > maxHistory) {
    history.shift();
  }

  localStorage.setItem(storageKey, JSON.stringify(history));

  return newItem;
};

const removeHistory = function (url: string) {
  let history = getHistory();
  history = history.filter((h: IHistoryItem) => h.url !== url);
  localStorage.setItem(storageKey, JSON.stringify(history));
};

const getProxyUrl = function (url: string, isStaging = false) {
  const location = new URL(url);
  const hostname = location?.hostname.replace(/[\W_]+/g, "-");
  let result = `https://${hostname}.${PROXY_HOST}/?url=${url}`;
  if (isStaging) {
    result += "&staging=1";
  }
  return result;
};

enum EProxyStage {
  staging = "staging",
  production = "production",
}

export default function Home() {
  const [targetURL, setTargetURL] = useState<string>("");
  const [stage, setStage] = useState<EProxyStage>(EProxyStage.production);
  const inputRef = useRef<HTMLInputElement>(null);
  const [URLHistory, setURLHistory] = useState<IHistoryItem[]>(() =>
    getHistory()
  );
  const isStaging = stage === EProxyStage.staging;
  const handleURLInput = useCallback((e) => {
    const url = e.target.value;
    if ("http".indexOf((url || "").substr(0, 4)) !== 0) {
      setTargetURL(`https://${url}`);
    } else {
      setTargetURL(url);
    }
  }, []);
  const handleSubmit = useCallback(() => {
    if (targetURL && isUrl(targetURL)) {
      const newItem = addHistory(targetURL);
      window.open(getProxyUrl(newItem.url, isStaging), "_blank");
      setTargetURL("");
      setURLHistory(getHistory());
    }
  }, [targetURL, isStaging]);
  const handleHistoryRemove = useCallback((url) => {
    if (url) {
      removeHistory(url);
      setURLHistory(getHistory());
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div>
      <div className="flex justify-center pt-20">
        <div className="md:w-2/4 w-full p-6 md:px-0">
          <div className="relative mb-8 mx-auto w-44">
            <img className="inline-block w-44" src={logoImg} alt="logo" />
          </div>
          <form onSubmit={handleSubmit} id="urlForm">
            <div className="flex w-full">
              <select
                value={stage}
                onChange={(e) => setStage(e.target.value as EProxyStage)}
                className={`mr-2 px-4 py-2 border cursor-pointer ${
                  isStaging
                    ? "bg-yellow-200 border-yellow-300"
                    : "border-blue-200 bg-blue-100"
                } shadow rounded-l-full text-gray-600`}
              >
                <option value={EProxyStage.production}>Production</option>
                <option value={EProxyStage.staging}>Staging</option>
              </select>
              <div className="flex border border-gray-200 rounded-r-full p-2 shadow text-xl align-middle content-center w-full">
                <input
                  onInput={handleURLInput}
                  id="urlInput"
                  ref={inputRef}
                  value={targetURL}
                  type="text"
                  className="w-full outline-none px-3"
                  placeholder="https://"
                />
                <button
                  id="goBtn"
                  disabled={!targetURL}
                  className="bg-blue-500 rounded-full text-gray-50 text-xs font-bold p-2 w-8 h-8"
                >
                  Go
                </button>
              </div>
            </div>
          </form>
          <div className="mt-8" id="history">
            {URLHistory.map((h) => (
              <div className="history-item flex justify-start cursor-pointer text-gray-800 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                <span className="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
                <a
                  className="flex-grow font-medium px-2"
                  href={getProxyUrl(h.url, isStaging)}
                  target="_black"
                >
                  {h.name}
                </a>
                <span
                  className="history-item-delete font-medium px-2"
                  onClick={() => handleHistoryRemove(h.url)}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 bg-gray-200 border-t w-full flex justify-between text-gray-600 text-xs">
        <div className="flex">
          <a className="block p-3" href="/#">
            Advertising
          </a>
          <a className="block p-3" href="/#">
            Business
          </a>
          <a className="block p-3" href="/#">
            How It Works
          </a>
        </div>
        <div className="flex">
          <a className="block p-3" href="/#">
            Privacy
          </a>
          <a className="block p-3" href="/#">
            Terms
          </a>
          <a className="block p-3" href="/#">
            Settings
          </a>
        </div>
      </div>
    </div>
  );
}
