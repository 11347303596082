export function isUrl(url: string) {
  const toTest = url.trim();

  try {
    const url = new URL(toTest);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
}

export function getQueryParam(url: string, name: string, defaultValue = "") {
  const uObject = new URL(url);
  return uObject.searchParams.get(name) || defaultValue;
}

export function setQueryParam(
  url: string,
  keyValuePairs: Record<string, string>
) {
  const uObject = new URL(url);
  Object.entries(keyValuePairs).forEach(([key, value]) =>
    uObject.searchParams.set(key, value)
  );
  return uObject.href;
}

export function removeQueryParam(url: string, queryNames: string[]) {
  const uObject = new URL(url);
  queryNames.forEach((name) => uObject.searchParams.delete(name));
  return uObject.href;
}

export function replaceLocation(url: string) {
  window.history.pushState({}, document.title, url);
}
